<template>
  <v-app>
    <v-dialog v-model="isLoading" persistent width="300">
      <v-card color="primary" dark>
        <v-card-text>
          Loading
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-app-bar app elevate-on-scroll elevation="3" color="white">
      <v-app-bar-nav-icon @click.stop="drawer = !drawer" />
      <v-spacer></v-spacer>

      <v-menu offset-y>
        <template v-slot:activator="{ attrs, on }">
          <span style="cursor: pointer" v-bind="attrs" v-on="on">
            <v-chip link>
              <v-badge dot bottom color="green" offset-y="10" offset-x="10">
                <v-avatar size="40">
                  <v-img src="../assets/account.png"></v-img>
                </v-avatar>
              </v-badge>
              <span class="ml-3">{{ userData.nama }}</span>
            </v-chip>
          </span>
        </template>
        <v-list width="250" class="py-0">
          <v-list-item two-line>
            <v-list-item-avatar>
              <v-img src="../assets/account.png"></v-img>
            </v-list-item-avatar>

            <v-list-item-content>
              <v-list-item-title>{{ userData.nama }}</v-list-item-title>
              <v-list-item-subtitle style="color: green"
                >Online</v-list-item-subtitle
              >
            </v-list-item-content>
          </v-list-item>
          <v-divider />
          <v-list-item @click="toProfile()">
            <v-list-item-title>
              <v-icon class="mr-1">mdi-account</v-icon>
              Profil
            </v-list-item-title>
          </v-list-item>
          <v-list-item @click="logout()">
            <v-list-item-title>
              <v-icon class="mr-1">mdi-logout</v-icon>
              Keluar
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>

    <v-navigation-drawer app v-model="drawer" v-if="userData">
      <v-list-item class="px-6 my-4">
        <v-list-item-content>
          <v-list-item-title class="text-h5 mb-2">
            <strong> APERDU </strong>
          </v-list-item-title>
          <span class="subtitle-2">Aplikasi Perencanaan Anggaran Terpadu</span>
        </v-list-item-content>
      </v-list-item>

      <v-divider></v-divider>

      <v-list nav>
        <v-list-item :to="{ name: 'welcome' }">
          <v-list-item-icon>
            <v-icon>mdi-home</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>Home</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item
          :to="{ name: 'l_kanwil' }"
          v-if="checkAccess('200', userData.akses)"
        >
          <v-list-item-icon>
            <v-icon>mdi-bank</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>Satuan Kerja</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item
          :to="{ name: 'l_usergroup' }"
          v-if="checkAccess('100', userData.akses)"
        >
          <v-list-item-icon>
            <v-icon>mdi-account-multiple</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>User Group</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item
          :to="{ name: 'l_useradmin' }"
          v-if="checkAccess('101', userData.akses)"
        >
          <v-list-item-icon>
            <v-icon>mdi-account</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>User Admin</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item
          :to="{ name: 'l_rkbmn' }"
          v-if="checkAccess('301', userData.akses)"
        >
          <v-list-item-icon>
            <v-icon>mdi-widgets</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>Data RKBMN</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item
          :to="{ name: 'l_prioritas' }"
          v-if="checkAccess('302', userData.akses)"
        >
          <v-list-item-icon>
            <v-icon>mdi-alert-octagram</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>Belanja Prioritas</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item
          :to="{ name: 'l_rkakl' }"
          v-if="checkAccess('304', userData.akses)"
        >
          <v-list-item-icon>
            <v-icon>mdi-calculator</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>Data RKAKL</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-main>
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
export default {
  data() {
    return {
      drawer: null,
    };
  },
  computed: {
    userData() {
      return this.$store.getters.userData;
    },
    isLoading() {
      return this.$store.getters.isLoading;
    },
  },
  methods: {
    logout() {
      this.$store.dispatch("logout");
    },
    toProfile() {
      if (this.$route.name != "profil") {
        this.$router.push({ name: "profil" });
      }
    },
    checkAccess(nameKey, myArray) {
      for (var i = 0; i < myArray.length; i++) {
        if (myArray[i]["_id"] == nameKey) {
          return myArray[i];
        }
      }
    },
  },
};
</script>